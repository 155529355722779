import React, { useState } from "react";

import { IMAGE } from "../Constants/Image";
import ChangeSection from "../ui/login/ChangeSection";
import ForgetSection from "../ui/login/ForgetSection";
import LoginSection from "../ui/login/LoginSection";
import axios from "axios";

const Login = () => {
  const cancelTokenSource = axios.CancelToken.source();
  const [showForgetSection, setForgetSection] = useState(false);
  const [showLoginSection, setLoginSection] = useState(true);
  const [showChangeSection, setChangeSection] = useState(false);

  return (
    <div className="bg-white font-Flama-Regular font-bold">
      {cancelTokenSource.cancel()}
      <div className="w-full flex flex-wrap">
        <div className="w-full md:w-1/2 flex flex-col p-2 overflow-y-visible my-5 mb-5 h-full">
          {/* <!-- Login Section --> */}
          {showLoginSection && (
            <div className="mb-20 overflow-y-auto change no-scrollbar">
              <LoginSection
                setForgetSection={setForgetSection}
                setLoginSection={setLoginSection}
                setChangeSection={setChangeSection}
              ></LoginSection>
            </div>
          )}
          {/* <!-- Forget Section --> */}
          {showForgetSection && (
            <div className="mb-20 overflow-y-auto change no-scrollbar">
              <ForgetSection
                setForgetSection={setForgetSection}
                setLoginSection={setLoginSection}
                setChangeSection={setChangeSection}
              />
            </div>
          )}
          {/* <!-- Change Section --> */}
          {showChangeSection && (
            <div className="mb-20 overflow-y-auto change no-scrollbar">
              <ChangeSection
                setForgetSection={setForgetSection}
                setLoginSection={setLoginSection}
                setChangeSection={setChangeSection}
              ></ChangeSection>
            </div>
          )}
        </div>
        {/* <!-- Image Section --> */}
        <div className="w-1/2 shadow-2xl">
          <img
            className="object-cover w-full h-screen hidden md:block "
            src={IMAGE.LOGIN_LANDSCAPE}
            alt="Cover"
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
