import React, { useEffect, useState } from "react";
import TableLastPayment from "../table/home/TableLastPayment";
import TableOutstand from "../table/home/TableOutstand";
import * as ProviderService from "../../services/ProviderService";
import { RingLoader } from "react-spinners";
import Cookies from "universal-cookie";
import moment from "moment-timezone";
import swal from "sweetalert";
import "moment/locale/es";
import { useNavigate } from "react-router-dom";

export default function DetailedAccordion() {
  const columnsLastPayment = [
    { title: "Fecha pago", field: "FAVIS" },
    { title: "Doc.pago", field: "DOCUP" },
    { title: "Importe total", field: "VALTO" },
    { title: "Moneda", field: "WAERS" },
  ];
  const columnsOutstand = [
    { title: "Factura", field: "REF_DOC_NO" },
    { title: "Fecha", field: "DOC_DATE" },
    { title: "Importe", field: "LC_AMOUNT" },
    { title: "Moneda", field: "LOC_CURRCY" },
    /* { title: "Descripción fact.", field: "ITEM_TEXT" }, */
  ];

  const cookies = new Cookies();

  const [dataPayments, setDataPayments] = useState([]);
  const [dataInvoices, setDataInvoices] = useState([]);
  const [isLoadingPayments, setIsLoadingPayments] = useState(true);
  const [isLoadingOpenMatches, setIsLoadingOpenMatches] = useState(true);
  const [totalImportPayments, setTotalImportPayments] = useState("$0");
  const [totalImportInvoices, setTotalImportInvoices] = useState("$0");
  const navigate = useNavigate();

  const e_csap = cookies.get("e_csap");
  const society = cookies.get("society");

  useEffect(() => {
    const dataPayments = {
      Proveedor: e_csap,
      Sociedad: society,
    };
    ProviderService.consultaPagos(dataPayments)
      .then((response) => {
        if (response.error) {
          if (response.data.message === "Request failed with status code 500") {
          } else {
            swal({
              title: "Sesión expirada",
              text: "Su sesión se ha expirado",
              icon: "warning",
            });
            logOut();
          }
        } else {
          //First case: There are not payments
          let data1 = response.data["n0:PortalProveedoresDataResponse"];
          if (data1) {
            if (
              data1.consultaPagos === undefined ||
              data1.consultaPagos === "undefined" ||
              data1.consultaPagos === null
            ) {
              setDataPayments([]);
            } //Second case: There is only one payment
            else if (
              data1.consultaPagos.consultaPagos.length === undefined ||
              data1.consultaPagos.consultaPagos.length === "undefined" ||
              data1.consultaPagos.consultaPagos.length === null
            ) {
              data1 = [data1.consultaPagos.consultaPagos];
              fortmatPayments(data1);
            } else {
              // Third case: There are more than one payment
              fortmatPayments(data1.consultaPagos.consultaPagos);
            }
          } else {
            console.log("La data de consulta pagos llegó undefined");
          }
          setIsLoadingPayments(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoadingPayments(false);
      });

    const dataInvoices = {
      Proveedor: e_csap,
      Sociedad: society,
    };
    ProviderService.consultaPartidasAbiertas(dataInvoices)
      .then((response) => {
        if (response.data.name === "Error") {
          if (response.data.message === "Request failed with status code 500") {
            setIsLoadingOpenMatches(false);
          } else {
            swal({
              title: "Sesión expirada",
              text: "Su sesión se ha expirado",
              icon: "warning",
            });
            logOut();
            setIsLoadingOpenMatches(false);
          }
        } else {
          //First case: There are not payments
          let data2 = response.data["n0:PortalProveedoresDataResponse"];
          if (data2) {
            if (
              data2.consultaPartidasAbiertas === undefined ||
              data2.consultaPartidasAbiertas === "undefined" ||
              data2.consultaPartidasAbiertas === null
            ) {
              setDataInvoices([]);
            } //Second case: There is only one payment
            else if (
              data2.consultaPartidasAbiertas.consultaPartidasAbiertas.length ===
                undefined ||
              data2.consultaPartidasAbiertas.consultaPartidasAbiertas.length ===
                "undefined" ||
              data2.consultaPartidasAbiertas.consultaPartidasAbiertas.length ===
                null
            ) {
              data2 = [data2.consultaPartidasAbiertas.consultaPartidasAbiertas];
              formatInvoices(data2);
            } else {
              // Third case: There are more than one payment
              formatInvoices(
                data2.consultaPartidasAbiertas.consultaPartidasAbiertas
              );
            }
          } else {
            console.log(
              "La data de consulta partidas abiertas llegó undefined"
            );
          }
          setIsLoadingOpenMatches(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoadingOpenMatches(false);
      });
  }, []);

  const logOut = async () => {
    cookies.remove("e_csap");
    cookies.remove("user_portal");
    navigate("/");
  };

  const formatInvoices = (data) => {
    const monedaFormatter2 = new Intl.NumberFormat("es-ES", {
      style: "currency",
      currency: "COP",
    });
    moment.tz.setDefault("America/Bogota");
    let data4 = data;
    let summary = 0.0;
    data4.forEach((element) => {
      var valivAmount = element.LC_AMOUNT.includes("-");
      if (valivAmount) {
        summary = summary - parseFloat(element.LC_AMOUNT.replace("-", ""));
        element.LC_AMOUNT =
          "-$" +
          monedaFormatter2
            .format(element.LC_AMOUNT.replace("-", ""))
            .replace("COP", "");
      } else {
        summary = summary + parseFloat(element.LC_AMOUNT);
        element.LC_AMOUNT =
          "$" + monedaFormatter2.format(element.LC_AMOUNT).replace("COP", "");
      }
      element.DOC_DATE = moment(element.DOC_DATE)
        .tz("America/Bogota")
        .format("YYYY/MM/DD");
      element.REF_DOC_NO = element.REF_DOC_NO
        ? element.REF_DOC_NO.replace("-", "_")
        : "";
    });
    summary = "$" + monedaFormatter2.format("" + summary).replace("COP", "");
    setTotalImportInvoices(summary);
    setDataInvoices(data4);
  };

  const fortmatPayments = (data) => {
    const monedaFormatter = new Intl.NumberFormat("es-ES", {
      style: "currency",
      currency: "COP",
    });
    moment.tz.setDefault("America/Bogota");
    let data2 = data;
    let summary = 0.0;
    data2.forEach((element) => {
      var valivAmount = element.VALTO.includes("-");
      if (valivAmount) {
        summary = summary - parseFloat(element.VALTO.replace("-", ""));
        element.VALTO =
          "-$" +
          monedaFormatter
            .format(element.VALTO.replace("-", ""))
            .replace("COP", "");
      } else {
        summary = summary + parseFloat(element.VALTO);
        element.VALTO =
          "$" + monedaFormatter.format(element.VALTO).replace("COP", "");
      }
      element.FAVIS = moment(element.FAVIS)
        .tz("America/Bogota")
        .format("YYYY/MM/DD");
    });
    summary = "$" + monedaFormatter.format("" + summary).replace("COP", "");
    setTotalImportPayments(summary);
    setDataPayments(data2);
  };

  return (
    <div className="flex flex-col xl:flex-col lg:flex-col lg:flex sm:block md:block items-center">
      <div className="xl:w-9/12 lg:w-9/12 md:w-9/12 sm:w-9/12 w-9/12">
        <div className="w-full bg-blue-700 shadow-lg text-center justify-center items-center mx-4">
          <div className="inline-block align-middle">
            <p className="text-white font-Flama-Regular xl:text-lg lg:text-base sm:text-sm md:text-xs text-xs h-10 p-2">
              Listado de pagos: Parámetros de selección
            </p>
          </div>
        </div>
        <div className="w-full border-2 mx-4 border-gray-600 mb-4">
          {isLoadingPayments ? (
            <div className="flex items-center justify-center flex-col">
              <RingLoader color="blue" size={100} />
              <div className="text-center mb-20 ">
                <label className="mb-20 text-blue-500 text-sm">
                  Cargando tabla
                </label>
              </div>
            </div>
          ) : (
            <div className="p-4">
              <TableLastPayment
                columns={columnsLastPayment}
                data={dataPayments}
              ></TableLastPayment>
            </div>
          )}
        </div>
        <div className="w-full bg-blue-700 shadow-lg text-center justify-center items-center mx-4">
          <div className="inline-block align-middle">
            <p className="text-white font-Flama-Regular h-10 p-2">
              Importe total del listado de pagos: {totalImportPayments}
            </p>
          </div>
        </div>
      </div>
      <div className="xl:w-9/12 lg:w-9/12 md:w-9/12 sm:w-9/12 w-9/12 mt-10">
        <div className="w-full bg-blue-700 shadow-lg text-center justify-center items-center mx-4">
          <div className="inline-block align-middle">
            <p className="text-white font-Flama-Regular xl:text-lg lg:text-base sm:text-sm md:text-xs text-xs h-10 p-2">
              Facturas pendientes
            </p>
          </div>
        </div>
        <div className="w-full border-2 mx-4 border-gray-600 mb-4">
          {isLoadingOpenMatches ? (
            <div className="flex items-center justify-center flex-col">
              <RingLoader color="blue" size={100} />
              <div className="text-center mb-20 ">
                <label className="mb-20 text-blue-500 text-sm">
                  Cargando tabla
                </label>
              </div>
            </div>
          ) : (
            <div className="p-4">
              <TableOutstand
                columns={columnsOutstand}
                data={dataInvoices}
              ></TableOutstand>
            </div>
          )}
        </div>
        <div className="w-full bg-blue-700 shadow-lg text-center justify-center items-center mx-4">
          <div className="inline-block align-middle">
            <p className="text-white font-Flama-Regular h-10 p-2">
              Importe total de las facturas pendientes: {totalImportInvoices}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
