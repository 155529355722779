import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "firebase/auth";
import AccordingProfile from "../ui/accordion/according_profile";
import AccordingData from "../ui/accordion/according_data";
import Cookies from 'universal-cookie'

const Home = () => {
  const cookies = new Cookies()
  const navigate = useNavigate();
  useEffect(() => {
    if (cookies.get('e_csap')) {
    } else {
      navigate("/");
    }
  }, []);

  return (
    <div className="items-center">
      <main className="justify-center items-center my-10">
        <div className="w-auto bg-blue-700 h-12 shadow-lg text-center justify-center items-center mx-4">
          <div className="inline-block align-middle">
            <p className="text-white font-Flama-Regular xl:text-xl lg:text-lg md:text-base sm:text-sm text-xs p-2">
              Resumen
            </p>
          </div>
        </div>
        <div className="border-2 border-gray-600 mx-4">
          <div className="items-center p-5">
            <AccordingProfile></AccordingProfile>
            <div className="my-10"></div>
            <AccordingData></AccordingData>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Home;
