import React, { useEffect, useState } from "react";
import CardProfile from "../card/cardProfile";
import CardCominication from "../card/cardCommunication";
import * as ProviderService from "../../services/ProviderService";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

export default function DetailedAccordion() {
  const [provider, setProvider] = useState({});
  const [loading, setLoading] = useState(true);
  const cookies = new Cookies();

  const e_csap = cookies.get("e_csap");
  const society = cookies.get("society");
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
      const data = {
        Proveedor: e_csap,
        Sociedad: society,
      };
      ProviderService.consultaDatosAcreedor(data)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.name === "Error") {
              if (
                response.data.message === "Request failed with status code 500"
              ) {
              } else {
                swal({
                  title: "Sesión expirada",
                  text: "Su sesión se ha expirado",
                  icon: "warning",
                });
                logOut();
              }
            } else {
              if (response.data) {
                var proveedor =
                  response.data["n0:PortalProveedoresDataResponse"];
                if (proveedor) {
                  setProvider(proveedor.consultaDatosAcreedor);
                  setLoading(false);
                }
              }
            }
          } else if (response.status === 403) {
            console.log(
              "Token expiro o se cerro el back y toca reinicio las cookiens o las cookies se borraron"
            );
            swal({
              title: "Sesión expirada",
              text: "Su sesión se ha expirado",
              icon: "warning",
            });
            logOut();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [loading]);

  const logOut = async () => {
    cookies.remove("e_csap");
    cookies.remove("user_portal");
    navigate("/");
  };

  return (
    <div>
      <div className="w-full bg-blue-700 shadow-lg text-center justify-center items-center">
        <div className="inline-block align-middle">
          <p className="text-white font-Flama-Regular xl:text-xl lg:text-lg md:text-base sm:text-sm text-xs h-10">
            Datos Proveedor
          </p>
        </div>
      </div>
      <div className="border-gray-600 border-2 p-4">
        {!loading && (
          <div className="flex max-h-full">
            <CardProfile provider={provider}></CardProfile>
            <CardCominication provider={provider}></CardCominication>
          </div>
        )}
        <div className="">
          <p
            variant="caption"
            className="font-Flama-Regular text-black xl:text-sm lg:text-sm md:text-sm sm:text-sm text-xs"
          >
            Sr. proveedor si sus datos se encuentran desactualizados por favor
            enviar copia del Rut al e-mail
            <br />
            <a
              href="#email"
              className="text-gray-800 xl:text-sm lg:text-sm md:text-sm sm:text-sm text-xs"
            >
              rutproveedores@colombina.com
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
