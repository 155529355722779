import axios from "axios";

export const validaAcceso = async (data) => {
  return await axios.post("https://djbf8k8960.execute-api.us-east-1.amazonaws.com/v1/api/validaacceso", {
    data,
  });
};

export const envioClave = async (data) => {
  return await axios.post("https://djbf8k8960.execute-api.us-east-1.amazonaws.com/v1/api/envioclave", {
    data,
  });
};

export const cambioClave = async (data) => {
  return await axios.post("https://djbf8k8960.execute-api.us-east-1.amazonaws.com/v1/api/cambioclave", {
    data,
  });
};

export const consultaPartidasAbiertas = async (data) => {
  return await axios.post(
    "https://djbf8k8960.execute-api.us-east-1.amazonaws.com/v1/api/consultapartidasabiertas",
    {
      data,
    }
  );
};

export const consultaCertificadosPublicados = async (data) => {
  return await axios.post(
    "https://djbf8k8960.execute-api.us-east-1.amazonaws.com/v1/api/consultacertificadospublicados",
    {
      data,
    }
  );
};

export const consultaPagos = async (data) => {
  return await axios.post("https://djbf8k8960.execute-api.us-east-1.amazonaws.com/v1/api/consultapagos", {
    data,
  });
};

export const consultaDatosAcreedor = async (data) => {
  return await axios.post(
    "https://djbf8k8960.execute-api.us-east-1.amazonaws.com/v1/api/consultadatosacreedor",
    {
      data,
    }
  );
};

export const consultaDetallePA = async (data) => {
  return await axios.post("https://djbf8k8960.execute-api.us-east-1.amazonaws.com/v1/api/consultadetallepa", {
    data,
  });
};

export const generarCertificadosPublicados = async (data) => {
  return await axios.post(
    "https://djbf8k8960.execute-api.us-east-1.amazonaws.com/v1/api/generarcertificadospublicados",
    {
      data,
    }
  );
};

export const consultaDetallePagos = async (data) => {
  return await axios.post("https://djbf8k8960.execute-api.us-east-1.amazonaws.com/v1/api/consultadetallepagos", {
    data,
  });
};
