import React, { useState, useEffect } from "react";
import DataPicker from "../ui/calendar/DataPicker";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import DescriptionIcon from "@material-ui/icons/Description";
import { COLORS } from "../Constants/Colors";
import TableSumPayment from "../ui/table/payment/TableSumPayment";
import TableDetailPayment from "../ui/table/payment/TableDetailPayment";
import Cookies from "universal-cookie";
import * as ProviderService from "../services/ProviderService";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import { RingLoader } from "react-spinners";

const useStyles = makeStyles((theme) => ({
  root: {
    background: `linear-gradient(45deg, ${COLORS.third} 30%, ${COLORS.third} 90%)`,
    fontSize: theme.typography.pxToRem(12),
    borderRadius: 2,
    border: 0,
    color: "white",
    height: 36,
    padding: "0 20px",
  },
}));

const Payment = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [dataPayments, setDataPayments] = useState([]);
  const [dataPaymentsDetails, setDataPaymentsDetails] = useState([]);
  const [dataService, setDataService] = useState(null);
  const [initialDate, setInitialDate] = useState(null);
  const [finalDate, setFinalDate] = useState(null);
  const columsSumPay = [
    { title: "Proveedor", field: "LIFNR" },
    { title: "Doc.pago", field: "DOCUP" },
    { title: "Fecha", field: "FAVIS" },
    { title: "Impte.pagado en ML", field: "VALTO" },
    { title: "Moneda", field: "WAERS" },
  ];
  const columsDetailPay = [
    { title: "Proveedor", field: "LIFNR" },
    { title: "Tipo Doc.", field: "TDOCU" },
    { title: "Descrip. documento", field: "DRETE" },
    { title: "Factura", field: "FACTU" },
    { title: "Fecha fact.", field: "FFACT" },
    { title: "Vlr. bruto", field: "VALBR" },
    { title: "Vlr. IVA", field: "VALIV" },
    { title: "Importe retención", field: "VALOR" },
    { title: "Base retención", field: "BASER" },
    { title: "% Ret.", field: "TASAR" },
    { title: "Vlr. neto", field: "VALNT" },
    { title: "Moneda", field: "WAERS" },
  ];

  const cookies = new Cookies();
  const e_csap = cookies.get("e_csap");
  const society = cookies.get("society");
  const user = cookies.get("user_portal");

  const [isLoadingPayments, setIsLoadingPayments] = useState(true);
  const [isLoadingPaymentsDetails, setIsLoadingPaymentsDetails] =
    useState(false);

  useEffect(() => {
    if (cookies.get("e_csap")) {
    } else {
      navigate("/");
    }
  }, []);

  const handlerDate = (date, type) => {
    if (type === "Inicial") {
      setInitialDate(date);
    } else {
      setFinalDate(date);
    }
  };

  const getData2 = (dataPaments) => {
    ProviderService.consultaPagos(dataPaments)
      .then((response) => {
        if (response.error) {
          if (response.data.message === "Request failed with status code 500") {
            console.log("servicio consultaPagos no responde");
          } else {
            console.log(response);
            swal({
              title: "Sesión expirada",
              text: "Su sesión se ha expirado",
              icon: "warning",
            });
            logOut();
          }
        } else {
          //First case: There are not payments
          let data1 = response.data["n0:PortalProveedoresDataResponse"];
          if (data1) {
            if (
              data1.consultaPagos === undefined ||
              data1.consultaPagos === "undefined" ||
              data1.consultaPagos === null
            ) {
              setDataPayments([]);
            } //Second case: There is only one payment
            else if (
              data1.consultaPagos.consultaPagos.length === undefined ||
              data1.consultaPagos.consultaPagos.length === "undefined" ||
              data1.consultaPagos.consultaPagos.length === null
            ) {
              data1 = [data1.consultaPagos.consultaPagos];
              let datos = fortmatPayments(data1);
              setDataPayments(datos);
            } else {
              // Third case: There are more than one payment
              let datos = fortmatPayments(data1.consultaPagos.consultaPagos);
              setDataPayments(datos);
            }
          } else {
            console.log("La data de consulta pagos llegó undefined");
          }
          setIsLoadingPayments(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoadingPayments(false);
      });
  };

  useEffect(() => {
    const dataPaments = {
      Proveedor: e_csap,
      Sociedad: society,
    };

    getData2(dataPaments);
  }, []);

  const getData = async () => {
    if (initialDate === null || finalDate === null) {
      swal({
        title: "Debes ingresar la fecha inicial y final para poder buscar",
        icon: "warning",
      });
    } else {
      if (finalDate < initialDate) {
        swal({
          title: "La fecha inicial debe ser menor a la fecha final",
          icon: "warning",
        });
      } else {
        let fechaInicio = moment(initialDate)
          .format("YYYY/MM/DD")
          .replaceAll("/", "");
        let fechaFin = moment(finalDate)
          .format("YYYY/MM/DD")
          .replaceAll("/", "");

        const dataPaments = {
          Proveedor: e_csap,
          Sociedad: society,
          FechaInicial: fechaInicio,
          FechaFinal: fechaFin,
        };

        setDataPaymentsDetails([]);
        setIsLoadingPayments(true);
        getData2(dataPaments);
      }
    }
  };

  const fortmatPayments = (data) => {
    const monedaFormatter = new Intl.NumberFormat("es-ES", {
      style: "currency",
      currency: "COP",
    });
    moment.tz.setDefault("America/Bogota");
    let data2 = data;
    data2.forEach((element) => {
      element.LIFNR = user;
      element.VALTO =
        "$" + monedaFormatter.format(element.VALTO).replace("COP", "");
      element.FAVIS = moment(element.FAVIS)
        .tz("America/Bogota")
        .format("YYYY/MM/DD");
    });
    return data2;
  };

  const fortmatPaymentsDetails = (data) => {
    const monedaFormatter = new Intl.NumberFormat("es-ES", {
      style: "currency",
      currency: "COP",
    });
    moment.tz.setDefault("America/Bogota");
    let data2 = data;
    data2.forEach((element) => {
      var valivTmp = element.VALIV.includes("-");
      if (valivTmp) {
        element.VALIV =
          "$" +
          monedaFormatter
            .format(element.VALIV.replace("-", ""))
            .replace("COP", "");
      } else {
        element.VALIV =
          element.VALIV.trim() + "" === "0.00"
            ? "$" + monedaFormatter.format(element.VALIV).replace("COP", "")
            : "-$" + monedaFormatter.format(element.VALIV).replace("COP", "");
      }
      var valbrTmp = element.VALBR.includes("-");
      if (valbrTmp) {
        element.VALBR =
          "$" +
          monedaFormatter
            .format(element.VALBR.replace("-", ""))
            .replace("COP", "");
      } else {
        element.VALBR =
          element.VALBR.trim() + "" === "0.00"
            ? "$" + monedaFormatter.format(element.VALBR).replace("COP", "")
            : "-$" + monedaFormatter.format(element.VALBR).replace("COP", "");
      }
      var valorTmp = element.VALOR.includes("-");
      if (valorTmp) {
        element.VALOR =
          "$" +
          monedaFormatter
            .format(element.VALOR.replace("-", ""))
            .replace("COP", "");
      } else {
        element.VALOR =
          element.VALOR.trim() + "" === "0.00"
            ? "$" + monedaFormatter.format(element.VALOR).replace("COP", "")
            : "-$" + monedaFormatter.format(element.VALOR).replace("COP", "");
      }
      var baserTmp = element.BASER.includes("-");
      if (baserTmp) {
        element.BASER =
          "$" +
          monedaFormatter
            .format(element.BASER.replace("-", ""))
            .replace("COP", "");
      } else {
        element.BASER =
          element.BASER.trim() + "" === "0.00"
            ? "$" + monedaFormatter.format(element.BASER).replace("COP", "")
            : "-$" + monedaFormatter.format(element.BASER).replace("COP", "");
      }
      var valntTmp = element.VALNT.includes("-");
      if (valntTmp) {
        element.VALNT =
          "$" +
          monedaFormatter
            .format(element.VALNT.replace("-", ""))
            .replace("COP", "");
      } else {
        element.VALNT =
          element.VALNT.trim() + "" === "0.00"
            ? "$" + monedaFormatter.format(element.VALNT).replace("COP", "")
            : "-$" + monedaFormatter.format(element.VALNT).replace("COP", "");
      }

      element.FFACT = moment(element.FFACT)
        .tz("America/Bogota")
        .format("YYYY/MM/DD");
    });
    return data2;
  };

  const logOut = async () => {
    cookies.remove("e_csap");
    cookies.remove("user_portal");
    navigate("/");
  };

  useEffect(() => {
    if (dataService) {
      showPaymentsDetail();
    }
  }, [dataService]);

  const showPaymentsDetail = () => {
    setIsLoadingPaymentsDetails(true);
    ProviderService.consultaDetallePagos(dataService)
      .then((response) => {
        if (response.data.name === "Error") {
          if (response.data.message === "Request failed with status code 500") {
            console.log("servicio GenerarCertificados no responde");
            setIsLoadingPaymentsDetails(false);
          } else {
            console.log(response);
            swal({
              title: "Sesión expirada",
              text: "Su sesión se ha expirado",
              icon: "warning",
            });
            logOut();
            setIsLoadingPaymentsDetails(false);
          }
        } else {
          let data1 = response.data["n0:PortalProveedoresDataResponse"];
          if (data1) {
            if (
              data1.consultaDetallePagos === undefined ||
              data1.consultaDetallePagos === "undefined" ||
              data1.consultaDetallePagos === null
            ) {
              setDataPaymentsDetails([]);
            } //Second case: There is only one payment
            else if (
              data1.consultaDetallePagos.consultaDetallePagos.length ===
                undefined ||
              data1.consultaDetallePagos.consultaDetallePagos.length ===
                "undefined" ||
              data1.consultaDetallePagos.consultaDetallePagos.length === null
            ) {
              data1 = [data1.consultaDetallePagos.consultaDetallePagos];
              let datos = fortmatPaymentsDetails(data1);
              setDataPaymentsDetails(datos);
            } else {
              // Third case: There are more than one payment
              let data2 = fortmatPaymentsDetails(
                data1.consultaDetallePagos.consultaDetallePagos
              );
              setDataPaymentsDetails(data2);
            }
          } else {
            console.log("La data de consulta pagos llegó undefined");
          }
          setIsLoadingPaymentsDetails(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoadingPaymentsDetails(false);
      });
  };

  const featureAdded = () => {
    swal({
      title: "Funcionalidad bloqueada",
      icon: "warning",
    });
  };

  return (
    <div className="items-center w-full">
      <main className="justify-center items-center my-10">
        <div className="w-auto bg-blue-700 h-12 shadow-lg text-center justify-center items-center mx-4">
          <div className="inline-block align-middle">
            <p className="text-white font-Flama-Regular xl:text-xl lg:text-lg md:text-base sm:text-sm text-xs p-2">
              Pagos
            </p>
          </div>
        </div>
        <div className="border-2 border-gray-600 mx-4">
          <div>
            <div className="w-2/5 bg-blue-700 shadow-lg text-center justify-center items-center mx-8 mt-10">
              <div className="inline-block align-middle">
                <p className="text-white font-Flama-Regular xl:text-base lg:text-sm md:text-xs sm:text-xs text-xs h-10 p-2">
                  Listado de pagos: Parámetros de selección
                </p>
              </div>
            </div>
            <div className="w-2/5 border-2 mx-8 border-gray-600 mb-4">
              <div className="p-4 flex flex-row justify-between space-x-4">
                <DataPicker
                  handlerDate={handlerDate}
                  enable={true}
                  type="Inicial"
                ></DataPicker>
                <DataPicker
                  handlerDate={handlerDate}
                  type="Final"
                  enable={true}
                ></DataPicker>
              </div>
            </div>
            <div className="border-gray-600 border-t-2 m-4 border-b-2">
              <div className="m-4">
                <div className="m-2">
                  <Button
                    className={classes.root}
                    startIcon={<SearchIcon />}
                    onClick={() => getData()}
                  >
                    Buscar
                  </Button>
                </div>
                {/*  <div className="m-2">
                  <Button
                    className={classes.root}
                    startIcon={<DescriptionIcon />}
                    onClick={featureAdded}
                  >
                    Generar Archivo Excel
                  </Button>
                </div> */}
              </div>
            </div>
            <div className="flex flex-col xl:flex-col lg:flex-col lg:flex sm:block md:block items-center">
              <div className="xl:w-4/5 lg:w-4/5 md:w-4/5 sm:w-6/12 w-6/12 mr-5">
                <div className="w-full bg-blue-700 shadow-lg text-center justify-center items-center mx-4">
                  <div className="inline-block align-middle">
                    <p className="text-white font-Flama-Regular xl:text-lg lg:text-base sm:text-sm md:text-xs text-xs h-10 p-2">
                      Resumen Pagos
                    </p>
                  </div>
                </div>
                <div className="w-full border-2 mx-4 border-gray-600 mb-4">
                  {isLoadingPayments ? (
                    <div className="flex items-center justify-center flex-col">
                      <RingLoader color="blue" size={100} />
                      <div className="text-center mb-20 ">
                        <label className="mb-20 text-blue-500 text-sm">
                          Cargando tabla
                        </label>
                      </div>
                    </div>
                  ) : (
                    <div className="p-4">
                      <TableSumPayment
                        columns={columsSumPay}
                        data={dataPayments}
                        setDataService={setDataService}
                      ></TableSumPayment>
                    </div>
                  )}
                </div>
              </div>
              <div className="xl:w-11/12 lg:w-11/12 md:w-11/12 sm:w-11/12 w-11/12">
                <div className="w-full bg-blue-700 shadow-lg text-center justify-center items-center mx-4">
                  <div className="inline-block align-middle">
                    <p className="text-white font-Flama-Regular xl:text-lg lg:text-base sm:text-sm md:text-xs text-xs h-10 p-2">
                      Detalle de pagos
                    </p>
                  </div>
                </div>
                <div className="w-full border-2 mx-4 border-gray-600 mb-4">
                  {isLoadingPaymentsDetails ? (
                    <div className="flex items-center justify-center flex-col">
                      <RingLoader color="blue" size={100} />
                      <div className="text-center mb-20 ">
                        <label className="mb-20 text-blue-500 text-sm">
                          Cargando tabla
                        </label>
                      </div>
                    </div>
                  ) : (
                    <div className="p-4">
                      <TableDetailPayment
                        columns={columsDetailPay}
                        data={dataPaymentsDetails}
                      ></TableDetailPayment>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="items-center justify-center text-center"></div>
      </main>
    </div>
  );
};

export default Payment;
