let LOCALIZATOR = {
  toolbar: {
    searchPlaceholder: "Buscar",
    searchTooltip: "Buscar",
    exportTitle: "Exportar los registros",
  },
  pagination: {
    labelRowsPerPage: "Hola",
    labelRowsSelect: "registros",
  },
  body: {
    deleteTooltip: "Eliminar",
    addTooltip: "Agregar",
    editTooltip: "Editar",
    emptyDataSourceMessage: `No hay registros!`,
    editRow: {
      deleteText: "¿Quieres eliminar el registro?",
      saveTooltip: "Guarda",
      cancelTooltip: "Cancelar",
    },
  },
  grouping: {
    groupedBy: "Agrupado por: ",
    placeholder: "Deslizar caracteristica para agrupar",
  },
};

let LOCALIZATOR_PARTIDAS = {
  toolbar: {
    searchPlaceholder: "Buscar",
    searchTooltip: "Buscar",
    exportTitle: "Exportar los registros",
  },
  pagination: {
    labelRowsPerPage: "Hola",
    labelRowsSelect: "registros",
  },
  body: {
    deleteTooltip: "Eliminar",
    addTooltip: "Agregar",
    editTooltip: "Editar",
    emptyDataSourceMessage: `No tiene retenciones asociados a este documento `,
    editRow: {
      deleteText: "¿Quieres eliminar el registro?",
      saveTooltip: "Guarda",
      cancelTooltip: "Cancelar",
    },
  },
  grouping: {
    groupedBy: "Agrupado por: ",
    placeholder: "Deslizar caracteristica para agrupar",
  },
};

export { LOCALIZATOR, LOCALIZATOR_PARTIDAS };
