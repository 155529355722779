import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { IMAGE } from "../../Constants/Image";
import { COLORS } from "../../Constants/Colors";
import { useNavigate } from "react-router-dom";

import HomeIcon from "@material-ui/icons/Home";
import PaymentIcon from "@material-ui/icons/Payment";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import TimelineIcon from "@material-ui/icons/Timeline";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import Cookies from "universal-cookie";

const SideBar = () => {
  const cookies = new Cookies();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const logOut = async () => {
    cookies.remove("e_csap");
    cookies.remove("user_portal");
    navigate("/");
  };

  return (
    <>
      <nav
        className={
          isOpen ? "hidden" : "flex flex-col bg-white w-full pl-6 pt-4 pb-6"
        }
      >
        <div className="flex flex-col border-b items-center justify-between pb-2">
          <span className="mt-2 text-2xl dark:text-gray-300 font-extrabold capitalize font-Flama-Regular">
            PORTAL DE
          </span>
          <span className="text-2xl dark:text-gray-300 font-extrabold capitalize font-Flama-Regular">
            PROVEEDORES
          </span>
        </div>

        <div className="mt-8">
          <div className="flex flex-row">
            <img
              className="h-12 w-12 rounded-full"
              src={IMAGE.LOGIN_USER}
              alt="ImagenUser"
            />
            <div>
              <div className="mx-4 text-xl dark:text-gray-300 font-Flama-Regular font-extrabold">
                USUARIO
              </div>
              <span className="mx-4 text-xl dark:text-gray-300 font-Flama-Regular">
                {cookies.get("user_portal") ? cookies.get("user_portal") : ""}
              </span>
            </div>
          </div>
        </div>

        <div className="mt-10">
          <NavLink
            className="text-black block hover:bg-blue-500 hover:text-white h-12 items-center p-2 rounded"
            activeClassName="text-blue-600"
            exact="true"
            to="/Inicio"
            end={true}
          >
            Inicio
          </NavLink>
          <NavLink
            className="text-black block hover:bg-blue-500 hover:text-white h-12 items-center p-2 rounded"
            activeClassName="text-blue-600"
            exact="true"
            to="/Pagos"
          >
            Pagos
          </NavLink>
          <NavLink
            className="text-black block hover:bg-blue-500 hover:text-white h-12 items-center p-2 rounded"
            activeClassName="text-blue-600"
            exact="true"
            to="/Certificados"
          >
            Certificados
          </NavLink>
          <NavLink
            className="text-black block hover:bg-blue-500 hover:text-white h-12 items-center p-2 rounded"
            activeClassName="text-blue-600"
            exact="true"
            to="/Estado"
          >
            Estado de Cuenta
          </NavLink>
        </div>

        <div
          className="mt-auto flex items-center text-red-700 dark:text-red-400 cursor-pointer"
          onClick={logOut}
        >
          <p className="flex items-center">
            <ExitToAppIcon fontSize="large"></ExitToAppIcon>
            <span className="ml-2 capitalize font-medium">Cerrar Sesión</span>
          </p>
        </div>
      </nav>
      <div className="flex flex-col bg-white dark:bg-gray-900 px-4 pt-4 pb-6 border-r w-20">
        <button
          className="inline-flex items-center justify-center rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white"
          onClick={() => setIsOpen(!isOpen)}
        >
          <svg
            className="block h-8 w-8"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
          <svg
            className="hidden h-8 w-8"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        {isOpen && (
          <>
            <div className="border-b my-5"></div>
            <div className="py-10 flex flex-col p-2 justify-between">
              <NavLink
                exact="true"
                to="/Inicio"
                className="transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110 h-16 w-16"
              >
                <HomeIcon
                  titleAccess="Inicio"
                  fontSize="large"
                  style={{
                    color: COLORS.third,
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                ></HomeIcon>
              </NavLink>
              <NavLink
                exact="true"
                to="/Pagos"
                className="transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110 h-16 w-16"
              >
                <PaymentIcon
                  titleAccess="Pagos"
                  fontSize="large"
                  style={{
                    color: COLORS.third,
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                ></PaymentIcon>
              </NavLink>
              <NavLink
                exact="true"
                to="/Certificados"
                className="transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110 h-16 w-16"
              >
                <VerifiedUserIcon
                  titleAccess="Certificados"
                  fontSize="large"
                  style={{
                    color: COLORS.third,
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                ></VerifiedUserIcon>
              </NavLink>

              <NavLink
                exact="true"
                to="/Estado"
                className="transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110 h-16 w-16"
              >
                <TimelineIcon
                  titleAccess="Estado de Cuenta"
                  fontSize="large"
                  style={{
                    color: COLORS.third,
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                ></TimelineIcon>
              </NavLink>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SideBar;
