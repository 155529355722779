import React, { useState, useEffect } from "react";
import SelectItem from "../ui/selectItem/SelectItem";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { COLORS } from "../Constants/Colors";
import TableCertificate from "../ui/table/certificates/TableCertificate";
import Cookies from "universal-cookie";
import * as ProviderService from "../services/ProviderService";
import { RingLoader, BarLoader } from "react-spinners";
import moment from "moment";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    background: `linear-gradient(45deg, ${COLORS.third} 30%, ${COLORS.third} 90%)`,
    fontSize: theme.typography.pxToRem(12),
    borderRadius: 2,
    border: 0,
    color: "white",
    height: 36,
    padding: "0 20px",
  },
}));

const Certificates = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCertificate, setIsLoadingCertificate] = useState(false);
  const [data, setData] = useState([]);
  const [dataService, setDataService] = useState(null);
  const [file, setFile] = useState(null);
  const navigate = useNavigate();

  const cookies = new Cookies();
  const society = cookies.get("society");

  const classes = useStyles();

  const columsCertificate = [
    { title: "Tipo de certificado", field: "WITHT" },
    { title: "Período Ini.", field: "FPECI" },
    { title: "Período Fin.", field: "FPECF" },
  ];
  const handleSelected = (select) => {
    if (select) {
      const dataConsultaCertificados = {
        Sociedad: society,
        TipoCertificado: select,
      };
      setData([]);
      setIsLoading(true);
      ProviderService.consultaCertificadosPublicados(dataConsultaCertificados)
        .then((response) => {
          if (response.data.name === "Error") {
            if (
              response.data.message === "Request failed with status code 500"
            ) {
              setIsLoadingCertificate(false);
            } else {
              swal({
                title: "Sesión expirada",
                text: "Su sesión se ha expirado",
                icon: "warning",
              });
              logOut();
              setIsLoadingCertificate(false);
            }
          } else {
            let data = response.data["n0:PortalProveedoresDataResponse"];
            let consulta = data["consultaCertificadosPublicados"];
            let consultaData = consulta["consultaCertificadosPublicados"];

            var dataResult = [];
            if (
              consultaData.length === undefined ||
              consultaData.length === "undefined" ||
              consultaData.length === null
            ) {
              let FPECIdate = moment(consultaData.FPECI).format("YYYY/MM/DD");
              let FPECFdata = moment(consultaData.FPECF).format("YYYY/MM/DD");
              consultaData.FPECI = FPECIdate;
              consultaData.FPECF = FPECFdata;
              dataResult.push(consultaData);
              setData(dataResult);
            } else {
              for (const element in consultaData) {
                let FPECIdate = moment(consultaData[element].FPECI).format(
                  "YYYY/MM/DD"
                );
                let FPECFdata = moment(consultaData[element].FPECF).format(
                  "YYYY/MM/DD"
                );
                consultaData[element].FPECI = FPECIdate;
                consultaData[element].FPECF = FPECFdata;
              }
              setData(consultaData);
            }

            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {}, [isLoading]);

  const generarCertificado = () => {
    setFile(null);
    if (dataService) {
      setIsLoadingCertificate(true);
      ProviderService.generarCertificadosPublicados(dataService)
        .then((response) => {
          if (response.data.name === "Error") {
            if (
              response.data.message === "Request failed with status code 500"
            ) {
              setIsLoadingCertificate(false);
            } else {
              swal({
                title: "Sesión expirada",
                text: "Su sesión se ha expirado",
                icon: "warning",
              });
              logOut();
              setIsLoadingCertificate(false);
            }
          } else {
            setIsLoadingCertificate(false);
            var data = response.data["n0:PortalProveedoresDataResponse"];
            let consulta = data["generarCertificadosPublicados"];
            if (consulta) {
              if (consulta["E_BINF"]) {
                let stringPdf = consulta["E_BINF"];
                if (stringPdf) {
                  var byteArray = new Uint8Array(stringPdf.length / 2);
                  for (var x = 0; x < byteArray.length; x++) {
                    byteArray[x] = parseInt(stringPdf.substr(x * 2, 2), 16);
                  }
                  var blob = new Blob([byteArray], {
                    type: "application/pdf",
                  });
                  var blobUrl = URL.createObjectURL(blob);
                  setFile(blobUrl);
                  /*  var FileSaver = require("file-saver");
                   FileSaver.saveAs(blob, "Certificado.pdf"); */
                }
              } else if (consulta["E_MENS"]) {
                swal({
                  title: "Generar Certificado",
                  text: consulta["E_MENS"],
                  icon: "warning",
                });
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
          setIsLoadingCertificate(false);
        });
    }
  };

  useEffect(() => {}, [file]);

  const logOut = async () => {
    cookies.remove("e_csap");
    cookies.remove("user_portal");
    navigate("/");
  };

  return (
    <div className="items-center w-full">
      <main className="justify-center items-center my-10">
        <div className="w-auto bg-blue-700 h-12 shadow-lg text-center justify-center items-center mx-4">
          <div className="inline-block align-middle">
            <p className="text-white font-Flama-Regular xl:text-xl lg:text-lg md:text-base sm:text-sm text-xs p-2">
              Generación de Certificados
            </p>
          </div>
        </div>
        <div className="border-2 border-gray-600 mx-4">
          <div className="flex flex-row">
            <div>
              <div className="bg-blue-700 shadow-lg text-center justify-center items-center mx-4 mt-10">
                <div className="inline-block align-middle">
                  <p className="text-white font-Flama-Regular xl:text-base lg:text-xs sm:text-xs md:text-xs text-xs h-10 p-2">
                    Parámetros de Selección
                  </p>
                </div>
              </div>
              <div className="border-2 mx-4 border-gray-600 mb-4">
                <div className="p-4">
                  <SelectItem handleSelected={handleSelected}></SelectItem>
                </div>
              </div>
            </div>
            <div className="flex justify-center mt-10">
              <div className="">
                <div className="w-full bg-blue-700 shadow-lg text-center justify-center items-center mx-8">
                  <div className="inline-block align-middle">
                    <p className="text-white font-Flama-Regular xl:text-lg lg:text-base sm:text-sm md:text-xs text-xs h-10 p-2">
                      Certificados
                    </p>
                  </div>
                </div>
                <div className="w-full border-2 mx-8 border-gray-600 mb-4">
                  <div className="p-6">
                    {isLoading ? (
                      <div className="flex items-center justify-center flex-col">
                        <BarLoader color="blue" height={20} width={450} />
                        <div className="text-center mb-20">
                          <label className="mb-20 text-blue-500 text-sm">
                            Cargando tabla
                          </label>
                        </div>
                      </div>
                    ) : (
                      <TableCertificate
                        columns={columsCertificate}
                        data={data}
                        setData={setData}
                        setDataService={setDataService}
                      ></TableCertificate>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border-gray-600 border-t-2 m-4 border-b-2">
            <div className="m-4 flex justify-center">
              <Button
                className={classes.root}
                startIcon={<PictureAsPdfIcon />}
                onClick={generarCertificado}
                disabled={dataService ? false : true}
              >
                Generar Certificado
              </Button>
            </div>
            {isLoadingCertificate ? (
              <div className="flex items-center justify-center flex-col">
                <RingLoader color="blue" size={200} />
                <div className="text-center mb-20 ">
                  <label className="mb-20 text-blue-500 text-sm">
                    Generando Certificado
                  </label>
                </div>
              </div>
            ) : (
              <div></div>
            )}
            {file && (
              <div className="mb-6 flex justify-center">
                <embed src={file} width="900px" height="900px" />
              </div>
            )}
          </div>
        </div>

        <div className="items-center justify-center text-center"></div>
      </main>
    </div>
  );
};

export default Certificates;
