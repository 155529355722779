import React, { useState, useEffect } from "react";
import DataPicker from "../ui/calendar/DataPicker";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import DescriptionIcon from "@material-ui/icons/Description";
import { COLORS } from "../Constants/Colors";
import TableAccountDetail from "../ui/table/accountState/TableAccountDetail";
import TableAccounSummary from "../ui/table/accountState/TableAccountSummary";

import * as ProviderService from "../services/ProviderService";
import { RingLoader } from "react-spinners";
import swal from "sweetalert";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import "moment/locale/es";

const useStyles = makeStyles((theme) => ({
  root: {
    background: `linear-gradient(45deg, ${COLORS.third} 30%, ${COLORS.third} 90%)`,
    fontSize: theme.typography.pxToRem(12),
    borderRadius: 2,
    border: 0,
    color: "white",
    height: 36,
    padding: "0 20px",
  },
}));

const AccountState = () => {
  const classes = useStyles();
  const [date, setDate] = useState();
  const [value, setValue] = useState("");
  const [dataInvoices, setDataInvoices] = useState([]);
  const [dataDetail, setDataDetail] = useState([]);
  const [isLoadingOpenMatches, setIsLoadingOpenMatches] = useState(true);
  const [isLoadingDetail, setIsLoadingDetail] = useState(false);
  const [dataService, setDataService] = useState(null);
  const navigate = useNavigate();

  const columnsOutstand = [
    { title: "Proveedor", field: "VENDOR" },
    { title: "Factura", field: "REF_DOC_NO" },
    { title: "Fecha fac.", field: "DOC_DATE" },
    { title: "Fecha pago", field: "FECHAPAGO" },
    { title: "Importe bruto", field: "DISC_BASE" },
    { title: "Importe neto", field: "LC_AMOUNT" },
    { title: "Moneda", field: "LOC_CURRCY" },
    /* { title: "Texto documento", field: "ITEM_TEXT" }, */
  ];
  const columsAccountDetail = [
    { title: "Tipo documento", field: "TRETE" },
    { title: "Descrip. documento", field: "DRETE" },
    { title: "Importe", field: "DMBTR" },
    { title: "Moneda", field: "WAERS" },
  ];

  const cookies = new Cookies();
  const e_csap = cookies.get("e_csap");
  const society = cookies.get("society");

  useEffect(() => {
    const dataInvoices = {
      Proveedor: e_csap,
      Sociedad: society,
    };
    ProviderService.consultaPartidasAbiertas(dataInvoices)
      .then((response) => {
        if (response.data.name === "Error") {
          if (response.data.message === "Request failed with status code 500") {
            setIsLoadingOpenMatches(false);
          } else {
            swal({
              title: "Sesión expirada",
              text: "Su sesión se ha expirado",
              icon: "warning",
            });
            logOut();
            setIsLoadingOpenMatches(false);
          }
        } else {
          //First case: There are not payments
          let data2 = response.data["n0:PortalProveedoresDataResponse"];
          if (data2) {
            if (
              data2.consultaPartidasAbiertas === undefined ||
              data2.consultaPartidasAbiertas === "undefined" ||
              data2.consultaPartidasAbiertas === null
            ) {
              setDataInvoices([]);
            } //Second case: There is only one payment
            else if (
              data2.consultaPartidasAbiertas.consultaPartidasAbiertas.length ===
                undefined ||
              data2.consultaPartidasAbiertas.consultaPartidasAbiertas.length ===
                "undefined" ||
              data2.consultaPartidasAbiertas.consultaPartidasAbiertas.length ===
                null
            ) {
              data2 = [data2.consultaPartidasAbiertas.consultaPartidasAbiertas];
              formatInvoices(data2);
            } else {
              // Third case: There are more than one payment
              formatInvoices(
                data2.consultaPartidasAbiertas.consultaPartidasAbiertas
              );
            }
          } else {
            console.log(
              "La data de consulta partidas abiertas llegó undefined"
            );
          }
          setIsLoadingOpenMatches(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoadingOpenMatches(false);
      });
  }, []);

  useEffect(() => {
    if (dataService) {
      setIsLoadingDetail(true);
      ProviderService.consultaDetallePA(dataService)
        .then((response) => {
          if (response.data.name === "Error") {
            if (
              response.data.message === "Request failed with status code 500"
            ) {
              setIsLoadingDetail(false);
            } else {
              swal({
                title: "Sesión expirada",
                text: "Su sesión se ha expirado",
                icon: "warning",
              });
              logOut();
              setIsLoadingDetail(false);
            }
          } else {
            //First case: There are not payments
            let data = response.data["n0:PortalProveedoresDataResponse"];
            let consulta = data["consultaDetallePA"];
            if (consulta) {
              let consultaData = consulta["consultaDetallePA"];
              if (consultaData) {
                if (
                  consultaData.length === undefined ||
                  consultaData.length === "undefined" ||
                  consultaData.length === null
                ) {
                  consultaData = [consultaData];
                  formatDetail(consultaData);
                } else {
                  formatDetail(consultaData);
                }
              }
              setIsLoadingDetail(false);
              setDataService(null);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          setIsLoadingDetail(false);
        });
    }
  }, [dataService]);

  const logOut = async () => {
    cookies.remove("e_csap");
    cookies.remove("user_portal");
    navigate("/");
  };

  const formatInvoices = (data) => {
    const monedaFormatter2 = new Intl.NumberFormat("es-ES", {
      style: "currency",
      currency: "COP",
    });
    moment.tz.setDefault("America/Bogota");
    let data4 = data;
    data4.forEach((element) => {
      var DISC_BASETmp = element.DISC_BASE.includes("-");
      if (!DISC_BASETmp) {
        element.DISC_BASE =
          element.DISC_BASE.trim() + "" === "0.00"
            ? "$" +
              monedaFormatter2.format(element.DISC_BASE).replace("COP", "")
            : "$" +
              monedaFormatter2.format(element.DISC_BASE).replace("COP", "");
      } else {
        element.DISC_BASE =
          element.DISC_BASE.trim() + "" === "0.00"
            ? "$" +
              monedaFormatter2.format(element.DISC_BASE).replace("COP", "")
            : "-$" +
              monedaFormatter2
                .format(element.DISC_BASE.replace("-", ""))
                .replace("COP", "");
      }

      var LC_AMOUNTTmp = element.LC_AMOUNT.includes("-");
      if (!LC_AMOUNTTmp) {
        element.LC_AMOUNT =
          element.LC_AMOUNT.trim() + "" === "0.00"
            ? "$" +
              monedaFormatter2.format(element.LC_AMOUNT).replace("COP", "")
            : "$" +
              monedaFormatter2.format(element.LC_AMOUNT).replace("COP", "");
      } else {
        element.LC_AMOUNT =
          element.LC_AMOUNT.trim() + "" === "0.00"
            ? "$" +
              monedaFormatter2.format(element.LC_AMOUNT).replace("COP", "")
            : "-$" +
              monedaFormatter2
                .format(element.LC_AMOUNT.replace("-", ""))
                .replace("COP", "");
      }

      element.DOC_DATE = moment(element.DOC_DATE)
        .tz("America/Bogota")
        .format("YYYY/MM/DD");
      element.REF_DOC_NO = element.REF_DOC_NO
        ? element.REF_DOC_NO.replace("-", "_")
        : "";
      element.FECHAPAGO = moment(element.FECHAPAGO)
        .tz("America/Bogota")
        .format("YYYY/MM/DD");
      if (element.ITEM_TEXT) {
        var itemTmp = element.ITEM_TEXT.replace("-", " ");
        itemTmp.replace("\n", " ");
        element.ITEM_TEXT = itemTmp;
      } else {
        element.ITEM_TEXT = "";
      }
    });
    setDataInvoices(data4);
  };

  const formatDetail = (data) => {
    const monedaFormatter2 = new Intl.NumberFormat("es-ES", {
      style: "currency",
      currency: "COP",
    });
    let data4 = data;
    data4.forEach((element) => {
      var DMBTRTmp = element.DMBTR.includes("-");
      if (DMBTRTmp) {
        element.DMBTR =
          element.DMBTR.trim() + "" === "0.00"
            ? "$" + monedaFormatter2.format(element.DMBTR).replace("COP", "")
            : "-$" + monedaFormatter2.format(element.DMBTR).replace("COP", "");
      } else {
        element.DMBTR =
          "$" +
          monedaFormatter2
            .format(element.DMBTR.replace("-", ""))
            .replace("COP", "");
      }
    });
    setDataDetail(data4);
  };

  const handlerSearch = () => {
    var FECHAINICIAL = moment(date).tz("America/Bogota").format("YYYY/MM/DD");
    FECHAINICIAL = FECHAINICIAL.replaceAll("/", "");
    let tmp = value.replace("_", "-");
    var dataFilter = {
      Proveedor: e_csap,
      Sociedad: society,
      FechaInicial: FECHAINICIAL,
      Documento: tmp,
    };
    setIsLoadingOpenMatches(true);
    ProviderService.consultaPartidasAbiertas(dataFilter)
      .then((response) => {
        if (response.data.name === "Error") {
          if (response.data.message === "Request failed with status code 500") {
            setIsLoadingOpenMatches(false);
          } else {
            swal({
              title: "Sesión expirada",
              text: "Su sesión se ha expirado",
              icon: "warning",
            });
            logOut();
            setIsLoadingOpenMatches(false);
          }
        } else {
          //First case: There are not payments
          let data2 = response.data["n0:PortalProveedoresDataResponse"];
          if (data2) {
            if (
              data2.consultaPartidasAbiertas === undefined ||
              data2.consultaPartidasAbiertas === "undefined" ||
              data2.consultaPartidasAbiertas === null
            ) {
              setDataInvoices([]);
            } //Second case: There is only one payment
            else if (
              data2.consultaPartidasAbiertas.consultaPartidasAbiertas.length ===
                undefined ||
              data2.consultaPartidasAbiertas.consultaPartidasAbiertas.length ===
                "undefined" ||
              data2.consultaPartidasAbiertas.consultaPartidasAbiertas.length ===
                null
            ) {
              data2 = [data2.consultaPartidasAbiertas.consultaPartidasAbiertas];
              formatInvoices(data2);
            } else {
              // Third case: There are more than one payment
              formatInvoices(
                data2.consultaPartidasAbiertas.consultaPartidasAbiertas
              );
            }
            setDataDetail([]);
          } else {
            console.log(
              "La data de consulta partidas abiertas llegó undefined"
            );
          }
          setIsLoadingOpenMatches(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoadingOpenMatches(false);
      });
    // }
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const handlerDate = (date, type) => {
    setDate(date);
  };

  const featureAdded = () => {
    swal({
      title: "Funcionalidad bloqueada",
      icon: "warning",
    });
  };

  return (
    <div className="items-center w-full">
      <main className="justify-center items-center my-10">
        <div className="w-auto bg-blue-700 h-12 shadow-lg text-center justify-center items-center mx-4">
          <div className="inline-block align-middle">
            <p className="text-white font-Flama-Regular xl:text-xl lg:text-lg md:text-base sm:text-sm text-xs p-2">
              Estado de cuenta - Facturas pendientes
            </p>
          </div>
        </div>
        <div className="border-2 border-gray-600 mx-4">
          <div>
            <div className="xl:w-2/5 lg:w-3/6 md:w-2/6 sm:w-2/5 w-2/5 bg-blue-700 shadow-lg text-center justify-center items-center mx-8 mt-10">
              <div className="inline-block align-middle">
                <p className="text-white font-Flama-Regular xl:text-sm lg:text-xs md:text-xs sm:text-xs text-xs h-10 p-2">
                  Listado de facturas pendientes: Parámetros de selección
                </p>
              </div>
            </div>
            <div className="xl:w-2/5 lg:w-3/6 md:w-2/6 sm:w-2/5 w-2/5 border-2 mx-8 border-gray-600 mb-4">
              <div className="p-5 flex flex-col justify-between">
                <div className="flex flex-row justify-between">
                  <p className="text-black font-Flama-Regular text-xs h-10">
                    Fecha de Corte:
                  </p>
                  <DataPicker
                    handlerDate={handlerDate}
                    enable={false}
                    type="Corte"
                  ></DataPicker>
                </div>
                <div className="flex flex-row justify-between">
                  <p className="text-black font-Flama-Regular text-xs h-10">
                    Factura:
                  </p>
                  <input
                    className="h-10 w-64 border border-opacity-75"
                    id="standard-textarea"
                    label="Factura"
                    type="text"
                    placeholder="Ingresar factura"
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="border-gray-600 border-t-2 m-4 border-b-2">
              <div className="m-4">
                <div className="m-2">
                  <Button
                    className={classes.root}
                    startIcon={<SearchIcon />}
                    onClick={handlerSearch}
                  >
                    Buscar
                  </Button>
                </div>
                {/* <div className="m-2">
                  <Button
                    className={classes.root}
                    startIcon={<DescriptionIcon />}
                    onClick={featureAdded}
                  >
                    Generar Archivo Excel
                  </Button>
                </div> */}
              </div>
            </div>
            <div className="flex flex-col xl:flex-col lg:flex-col lg:flex sm:block md:block items-center">
              <div className="xl:w-11/12 lg:w-11/12 md:w-11/12 sm:w-11/12 w-11/12 mr-5">
                <div className="w-full bg-blue-700 shadow-lg text-center justify-center items-center mx-4">
                  <div className="inline-block align-middle">
                    <p className="text-white font-Flama-Regular xl:text-lg lg:text-base sm:text-sm md:text-xs text-xs h-10">
                      Resumen facturas pendientes
                    </p>
                  </div>
                </div>
                <div className="w-full border-2 mx-4 border-gray-600 mb-4">
                  <div className="p-6">
                    {isLoadingOpenMatches ? (
                      <div className="flex items-center justify-center flex-col">
                        <RingLoader color="blue" size={100} />
                        <div className="text-center mb-20 ">
                          <label className="mb-20 text-blue-500 text-sm">
                            Cargando tabla
                          </label>
                        </div>
                      </div>
                    ) : (
                      <TableAccounSummary
                        data={dataInvoices}
                        columns={columnsOutstand}
                        setDataService={setDataService}
                      ></TableAccounSummary>
                    )}
                  </div>
                </div>
              </div>
              <div className="xl:w-9/12 lg:w-9/12 md:w-9/12 sm:w-6/12 w-6/12 mr-10">
                <div className="w-full bg-blue-700 shadow-lg text-center justify-center items-center mx-4">
                  <div className="inline-block align-middle">
                    <p className="text-white font-Flama-Regular xl:text-lg lg:text-base sm:text-sm md:text-xs text-xs h-10">
                      Detalle del documento
                    </p>
                  </div>
                </div>
                <div className="w-full border-2 mx-4 border-gray-600 mb-4">
                  <div className="p-6">
                    {isLoadingDetail ? (
                      <div className="flex items-center justify-center flex-col">
                        <RingLoader color="blue" size={100} />
                        <div className="text-center mb-20 ">
                          <label className="mb-20 text-blue-500 text-sm">
                            Cargando tabla
                          </label>
                        </div>
                      </div>
                    ) : (
                      <TableAccountDetail
                        data={dataDetail}
                        columns={columsAccountDetail}
                      ></TableAccountDetail>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="items-center justify-center text-center"></div>
      </main>
    </div>
  );
};

export default AccountState;
