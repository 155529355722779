import React from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import tableIcons from "../IconsTable";
import { LOCALIZATOR } from "../../../Constants/Table";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 1,
  },
}));

const TableDetailPayment = ({ columns, data }) => {
  const classes = useStyles();
  /* const [myData, setMyData] = useState(data); */

  return (
    <div>
      <MaterialTable
        columns={columns}
        title=""
        data={data}
        icons={tableIcons}
        localization={LOCALIZATOR}
        options={{
          exportButton: {
            csv: true,
            pdf: false,
          },
          exportAllData: true,
          search: false,
          pageSizeOptions: [0],
          actionsColumnIndex: -1,
          headerStyle: {
            zIndex: 1,
            fontFamily: "Flama-Regular",
          },
        }}
      />
    </div>
  );
};

export default TableDetailPayment;
