import React, { useState } from "react";
import { IMAGE } from "../../Constants/Image";
/* import Transition from "./Transition";*/
// import "firebase/auth";
// import { useFirebaseApp, useUser } from "reactfire";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

const Header = ({ userName }) => {
  const [isMobile] = useState(false);

  const cookies = new Cookies();
  const navigate = useNavigate();
  const logOut = async () => {
    cookies.remove("e_csap");
    navigate("/");
  };

  const goToHome = () => {
    navigate("/Inicio", { state: { userName: userName } });
  };

  return (
    <div className="mb-16">
      <nav className="bg-white top-0 shadow-lg w-full fixed z-10">
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="flex h-16">
            {!isMobile && (
              <>
                <div className="items-center">
                  <div className="sm:hidden lg:block xl:block md:block hidden cursor-pointer">
                    <div onClick={goToHome}>
                      <img
                        className="h-16 w-40 overflow-hidden"
                        src={IMAGE.HOME_BUTTON}
                        alt="Workflow logo"
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className={!isMobile ? "hidden" : "block" && "md:hidden"}>
          <div className="pt-4 pb-3 border-t border-gray-700">
            <div className="flex items-center px-5">
              <div className="flex-shrink-0">
                <img
                  className="h-12 w-12 rounded-full"
                  src={IMAGE.LOGIN_USER}
                  alt=""
                />
              </div>
              <div className="ml-3">
                <div className="text-base font-Flama-Regular leading-none text-black">
                  {userName}
                </div>
                <div className="mt-1 text-sm font-Flama-Regular leading-none text-gray-800">
                  {cookies.get("user_portal") ? cookies.get("user_portal") : ""}
                </div>
              </div>
            </div>
          </div>
          <div className="px-2 pt-2 pb-3 sm:px-3">
            <button
              className="ml-3 relative hover:bg-blue-300 inline-block p-2 text-black font-Flama-Bold w-full"
              onClick={() => goToHome()}
            >
              Inicio
            </button>
          </div>

          <div className="pt-4 pb-3 border-t border-gray-800">
            <div className="mt-3 px-1">
              <button
                className="ml-3 relative hover:bg-blue-300 inline-block p-2 text-black font-Flama-Bold w-full"
                onClick={logOut}
              >
                Cerrar sesión
              </button>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
