const IMAGE = {
  COLOMBINA_IMAGE: require("../../resources/images/Colombina.png"),
  LOGIN_LANDSCAPE: require("../../resources/images/fondo.jpg"),
  LOGIN_USER: require("../../resources/images/login_user.jpg"),
  HOME_BUTTON: require("../../resources/images/home_button.png"),
  IMPORT: require("../../resources/images/import.png"),
  EXCEL: require("../../resources/images/excel.png"),
  NOT_PAGE: require("../../resources/images/page_not.svg"),
};

export { IMAGE };
