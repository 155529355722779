import React from "react";
import MaterialTable from "material-table";
import tableIcons from "../IconsTable";
import { LOCALIZATOR_PARTIDAS } from "../../../Constants/Table";

const TableAccountDetail = ({ columns, data }) => {
  return (
    <div>
      <MaterialTable
        columns={columns}
        title=""
        data={data}
        icons={tableIcons}
        localization={LOCALIZATOR_PARTIDAS}
        options={{
          exportButton: {
            csv: true,
            pdf: false,
          },
          exportAllData: true,
          search: false,
          pageSizeOptions: [0],
          actionsColumnIndex: -1,
          headerStyle: {
            zIndex: 1,
            fontFamily: "Flama-Regular",
          },
        }}
      />
    </div>
  );
};

export default TableAccountDetail;
