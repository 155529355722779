import React from "react";
import { BeatLoader } from "react-spinners";
import { IMAGE } from "../Constants/Image";

const Loading = () => {
  return (
    <>
      <div className="flex items-center justify-center h-screen flex-col">
        <img
          className="w-2/6 h-40%"
          src={IMAGE.COLOMBINA_IMAGE}
          alt="ColombinaLoading"
        />
        <BeatLoader size={40} color="red" loading />
      </div>
    </>
  );
};

export default Loading;
