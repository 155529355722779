import React, { useState, useEffect } from "react";

import { RingLoader } from "react-spinners";
import SendIcon from "@material-ui/icons/Send";


import * as ProviderService from "../../services/ProviderService";
import { IMAGE } from "../../Constants/Image";
import axios from "axios";
import swal from "sweetalert";

function ForgetSection({ setForgetSection, setLoginSection }) {
  const cancelTokenSource = axios.CancelToken.source();
  const [email, setEmail] = useState("");
  const [emailAlert, setEmailAlert] = useState("");
  const [emailRegister, setEmailRegister] = useState("");

  const [loading, setLoading] = useState(false);

  const validateUser = () => {
    validationEmail();
    if (email === null) {
      setEmailAlert("¡Debes escribir un usuario para recordar su contraseña!");
      return;
    }
    if (email) {
      setLoading(true);
      var dataService = {
        Usuario: email,
      };
      ProviderService.envioClave(dataService)
        .then((response) => {
          if (response.data.name === "Error") {
            swal({
              title: "Error",
              text: response.data.message,
              icon: "error",
            });
            setLoading(false);
          } else {
            setLoading(false);
            let data = response.data["n0:PortalProveedoresDataResponse"];
            let consulta = data["envioClave"];
            if (consulta) {
              if (consulta["E_MENS"] !== "Usuario no existe") {
                setEmailRegister(consulta["E_MENS"]);
                swal({
                  title: "Mensaje",
                  text: consulta["E_MENS"],
                  icon: "success",
                });
              } else {
                swal({
                  title: "¡Atención!",
                  text: consulta
                    ? consulta["E_MENS"]
                    : `No se encontro un correo asociado al usuario: ${email}`,
                  icon: "warning",
                });
              }
            } else {
              swal({
                title: "¡Atención!",
                text: `No se encontro un correo asociado al usuario: ${email}`,
                icon: "warning",
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
          swal({
            title: "Error",
            text: error,
            icon: "error",
          });
          setLoading(false);
        });
    }
  };

  /**
   * Email Validations
   */
  const validationEmail = () => {
    if (!email) {
      setEmailAlert("¡Debes escribir un usuario para recordar su contraseña!");
      return;
    } else {
      setEmailAlert("");
      return;
    }
  };

  useEffect(() => {
    if (email !== "") {
      validationEmail();
    }
  }, [email]);

  return (
    <>
      <div className="flex flex-col justify-center md:justify-start my-auto pt-8 md:pt-0 px-8 md:px-24 lg:px-32">
        {cancelTokenSource.cancel()}
        <img
          src={IMAGE.COLOMBINA_IMAGE}
          alt="imagen producto"
          className="w-1/3"
        />
        <p className="text-center text-3xl font-Flama-Regular font-bold">
          Recordar Clave
        </p>
        <form className="flex flex-col pt-3 md:pt-8 p-2">
          <div className="flex flex-col pt-4">
            <label className="text-lg font-Flama-Regular">Usuario</label>
            <div className="flex flex-row">
              <input
                id="users"
                placeholder="Usuario"
                type="text"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline mb-4"
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
                value={email}
              />
              <div
                className="transition duration-500 ease-in-out hover:bg-gray-400 transform hover:-translate-y-1 hover:scale-110 rounded-full h-16 w-16 flex items-center justify-center"
                type="button"
                onClick={validateUser}
              >
                <SendIcon
                  style={{ fontSize: 20 }}
                  titleAccess="Enviar"
                  fontSize="large"
                ></SendIcon>
              </div>
            </div>
            {emailAlert !== "" && (
              <div
                className="bg-red-100 border-l-4 border-red-500 text-red-700 p-2 mt-2"
                role="alert"
              >
                <p className="font-bold">Error:</p>
                <p>{emailAlert}</p>
              </div>
            )}
          </div>
        </form>
        {loading ? (
          <div className="flex items-center justify-center flex-col">
            <RingLoader color="blue" size={100} />
            <div className="text-center mb-20 ">
              <label className="mb-20 text-blue-500 text-sm">
                Validando usuario
              </label>
            </div>
          </div>
        ) : null}
        <div className="flex flex-col space-x-2">
          <p
            variant="caption"
            className="text-center font-Flama-Regular text-black xl:text-sm lg:text-sm md:text-sm sm:text-sm text-xs"
          >
            Sr. usuario, su nueva contraseña fue enviada al correo electrónico:
          </p>
          <input
            id="emailValid"
            placeholder="email"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline mb-4"
            defaultValue={emailRegister}
            disabled
          />
          <p
            variant="caption"
            className="font-Flama-Regular text-black xl:text-sm lg:text-sm md:text-sm sm:text-sm text-xs"
          >
            Si este correo se encuentra desactualizado, por favor enviar copia
            del Rut actualizado al e-mail
            <br />
            <a
              href="#email"
              className="text-gray-800 xl:text-sm lg:text-sm md:text-sm sm:text-sm text-xs"
            >
              rutproveedores@colombina.com
            </a>
          </p>
        </div>
        <div className="text-center mt-10 mb-20">
          <label
            className="underline font-semibold  cursor-pointer"
            onClick={() => {
              setForgetSection(false);
              setLoginSection(true);
            }}
          >
            Regresar
          </label>
        </div>
      </div>
    </>
  );
}

export default ForgetSection;
