import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import "./css/style.css";

import Login from "./components/pages/Login";
import Home from "./components/pages/Home";
import Header from "./components/ui/header/Header";
import SideBar from "./components/ui/navigation/SideBar";
import Payments from "./components/pages/Payment";
import Certificates from "./components/pages/Certificates";
import AccountStatus from "./components/pages/AccountState";
import NotFound from "./components/pages/NotFound";
import { PAGES } from "./components/Constants/Pages";

import Cookies from "universal-cookie";

function App() {
  const cookies = new Cookies();
  const navigate = useNavigate();
  let location = useLocation();
  useEffect(() => {
    if (
      cookies.get("e_csap") &&
      (location.pathname === "/" || location.pathname === "")
    ) {
      navigate("/Inicio");
    } else if (!cookies.get("e_csap") && location.pathname !== "/") {
      navigate("/");
    } else if (cookies.get("e_csap") && !find(location)) {
      navigate("/404");
    }
  }, [cookies.get("e_csap"), location.pathname]);

  const find = (location) => {
    var values = PAGES.find((page) => location.pathname === page.name);
    if (values) {
      return true;
    } else {
      return false;
    }
  };

  //Con este código se busca eliminar el componente de Export as PDF que contiene cada tabla del proyecto
  setTimeout(() => {
    // Wait until page load.
    new MutationObserver((mutations, observer) => {
      mutations.forEach((mutation) => {
        mutation.addedNodes.forEach((addedNode) => {
          if (addedNode.nodeType === Node.ELEMENT_NODE) {
            const element = addedNode;
            if (element.classList.contains("MuiPopover-root")) {
              Array.from(
                element.getElementsByClassName("MuiMenuItem-root")
              ).forEach((menuItem) => {
                if (menuItem.textContent === "Export as PDF") {
                  menuItem.remove();
                }
              });
            }
          }
        });
      });
    }).observe(document.body, { childList: true });
  }, 0);

  return (
    <div>
      <div className="h-screen w-full flex overflow-hidden">
        {cookies.get("e_csap") && location.pathname === "/404" && (
          <NotFound></NotFound>
        )}
        {cookies.get("e_csap") &&
          location.pathname !== "/" &&
          location.pathname !== "/404" && (
            <div className="flex min-h-full">
              <SideBar></SideBar>
              <Header></Header>
            </div>
          )}
        <div
          className={
            location.pathname === "/"
              ? "w-full"
              : "xl:w-4/5 lg:w-9/12 md:w-9/12 sm:w-3/5 flex-1 flex flex-col bg-gray-100 dark:bg-gray-700 transition duration-500 ease-in-out overflow-y-auto mt-10"
          }
        >
          <Routes>
            <Route path="/" element={<Login />} />
            {cookies.get("e_csap") && (
              <>
                <Route path="/Inicio" element={<Home />}></Route>
                <Route path="/Pagos" element={<Payments />}></Route>
                <Route path="/Certificados" element={<Certificates />}></Route>
                <Route path="/Estado" element={<AccountStatus />}></Route>
              </>
            )}
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default App;
