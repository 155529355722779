import React from "react";
import { NavLink } from "react-router-dom";
import { IMAGE } from "../Constants/Image";

const NotFound = () => {
  return (
    <div className="from-blue-700 bg-gradient-to-r to-blue-500 text-white min-h-screen flex items-center min-w-full">
      <div className="container mx-auto p-4 flex flex-wrap items-center">
        <div className="w-full md:w-5/12 text-center p-4">
          <img src={IMAGE.NOT_PAGE} alt="Not Found" />
        </div>
        <div className="w-full md:w-7/12 text-center md:text-left p-4">
          <div className="text-6xl font-medium">404</div>
          <div className="text-xl md:text-3xl font-medium mb-4">
            Oops. No existe esta página.
          </div>
          <div className="text-lg mb-8">
            Puede que hayas escrito mal la dirección o que la página se haya
            movido.
          </div>
          <NavLink
            className="border border-white rounded p-4"
            activeClassName="text-blue-600"
            exact="true"
            to="/Inicio"
          >
            Inicio
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
