import React from "react";

export default function SimpleCard({provider}) {
  return (
    <div className="w-full col-span-5 sm:col-span-6 md:col-span-4 lg:col-span-2 xl:col-span-2">
      <div className="bg-white shadow-lg rounded-lg px-4 py-4 mx-4 my-4">
        <div>
          <div className="flex">
            <div className="w-1/2 h-12">
              <p
                className="font-Flama-Regular xl:text-sm lg:text-sm md:text-sm sm:text-xs text-xs"
                color="textSecondary"
              >
                Sociedad:
              </p>
            </div>
            <div className="w-full h-12">
              <input disabled={true} className="w-full" value={provider.BUKRS}></input>
            </div>
          </div>
          <div className="flex">
            <div className="w-1/2 h-12">
              <p
                className="font-Flama-Regular xl:text-sm lg:text-sm md:text-sm sm:text-xs text-xs"
                color="textSecondary"
              >
                Acreedor:
              </p>
            </div>
            <div className="w-full h-12">
              <input
                disabled={true}
                className="w-full"
                value={provider.LIFNR}
              ></input>
            </div>
          </div>
          <div className="flex">
            <div className="w-1/2 h-12">
              <p
                className="font-Flama-Regular xl:text-sm lg:text-sm md:text-sm sm:text-xs text-xs"
                color="textSecondary"
              >
                No. ident.fis.1:
              </p>
            </div>
            <div className="w-full h-12">
              <input
                disabled={true}
                className="w-full"
                value={provider.NITAC}
              ></input>
            </div>
          </div>
          <div className="flex">
            <div className="w-1/2 h-12">
              <p
                className="font-Flama-Regular xl:text-sm lg:text-sm md:text-sm sm:text-xs text-xs"
                color="textSecondary"
              >
                Nombre acreedor:
              </p>
            </div>
            <div className="w-full h-12">
              <input
                disabled={true}
                className="w-full"
                value={provider.NOMBA}
              ></input>
            </div>
          </div>
          <div className="flex">
            <div className="w-1/2 h-12">
              <p
                className="font-Flama-Regular xl:text-sm lg:text-sm md:text-sm sm:text-xs text-xs"
                color="textSecondary"
              >
                Nombre empresa:
              </p>
            </div>
            <div className="w-full h-12">
              <input
                disabled={true}
                className="w-full"
                value={provider.NOMBE}
              ></input>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
