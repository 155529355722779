import React, { useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
} from "@material-ui/core";
import { COLORS } from "../../Constants/Colors";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: theme.typography.pxToRem(13),
    fontFamily: "Flama-Regular",
  },
  label: {
    fontSize: theme.typography.pxToRem(13),
    fontFamily: "Flama-Regular",
  },
  form: {
    display: "flex",
    flexDirection: "row",
    flexFlow: "row-reverse wrap",
  },
}));

const GRadio = withStyles({
  root: {
    color: COLORS.third,
  },
})((props) => <Radio color="default" {...props} />);

const SelectItem = ({ handleSelected }) => {
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = useState("");
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    handleSelected(event.target.value);
  };
  return (
    <div>
      <FormControl component="fieldset" className={classes.root}>
        <RadioGroup
          aria-label="gender"
          name="gender1"
          value={selectedValue}
          onChange={handleChange}
        >
          <FormControlLabel
            value="RF"
            control={<GRadio />}
            label={
              <Typography className={classes.label}>
                RF: Retención en la fuente renta
              </Typography>
            }
          />
          <FormControlLabel
            value="RC"
            control={<GRadio />}
            label={
              <Typography className={classes.label}>
                RC: Retención de Industria y Comercio
              </Typography>
            }
          />
          <FormControlLabel
            value="RV"
            control={<GRadio />}
            label={
              <Typography className={classes.label}>
                RV: Retención de IVA
              </Typography>
            }
          />
          <FormControlLabel
            value="SB"
            control={<GRadio />}
            label={
              <Typography className={classes.label}>
                SB: Sobretasa bomberil
              </Typography>
            }
          />
          <FormControlLabel
            value="CR"
            control={<GRadio />}
            label={
              <Typography className={classes.label}>
                CR: Retención en la fuente CREE
              </Typography>
            }
          />
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default SelectItem;
