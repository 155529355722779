import React, { useState, useEffect } from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import tableIcons from "../IconsTable";
import { LOCALIZATOR } from "../../../Constants/Table";
import { makeStyles } from "@material-ui/core/styles";
import Cookies from "universal-cookie";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 1,
  },
}));

const TableSumPayment = ({ columns, data, setDataService }) => {
  const classes = useStyles();
  /* const [myData, setMyData] = useState(data); */
  const cookies = new Cookies();

  const [itemSelect, setItemSelect] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    if (itemSelect) {
      const datoPagaDetails = {
        Sociedad: cookies.get("society"),
        Proveedor: itemSelect.LIFNR,
        Documento: itemSelect.DOCUP,
        Fecha: itemSelect.FAVIS.replaceAll("/", ""),
      };
      setDataService(datoPagaDetails);
    }
  }, [itemSelect]);

  return (
    <div>
      <MaterialTable
        columns={columns}
        title=""
        data={data}
        icons={tableIcons}
        onRowClick={(evt, selectedRow) => {
          setItemSelect(selectedRow);
          setSelectedRow(selectedRow.tableData.id);
        }}
        localization={LOCALIZATOR}
        options={{
          rowStyle: (rowData) => ({
            backgroundColor:
              selectedRow === rowData.tableData.id ? "#59A7F8" : "#EEE",
          }),
          exportButton: {
            csv: true,
            pdf: false,
          },
          exportAllData: true,
          search: false,
          pageSizeOptions: [0],
          actionsColumnIndex: -1,
          headerStyle: {
            zIndex: 1,
            fontFamily: "Flama-Regular",
          },
        }}
      />
    </div>
  );
};

export default TableSumPayment;
