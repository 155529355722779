import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import tableIcons from "../IconsTable";
import { LOCALIZATOR } from "../../../Constants/Table";
import Cookies from "universal-cookie";

const TableCertificate = ({ columns, data, setDataService }) => {
  const cookies = new Cookies();
  const [itemSelect, setItemSelect] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    const e_csap = cookies.get("e_csap");
    const society = cookies.get("society");
    if (itemSelect) {
      const dataCertificadosPublicados = {
        Sociedad: society,
        Proveedor: e_csap,
        TipoCertificado: itemSelect.WITHT,
        CertificadoHistorico: itemSelect.HISTO ? "X" : "",
        FechaInicial: itemSelect.FPECI.replaceAll("/", ""),
        FechaFinal: itemSelect.FPECF.replaceAll("/", ""),
      };
      setDataService(dataCertificadosPublicados);
    }
  }, [itemSelect]);

  return (
    <div>
      <MaterialTable
        columns={columns}
        title=""
        data={data}
        icons={tableIcons}
        onRowClick={(evt, selectedRow) => {
          setItemSelect(selectedRow);
          setSelectedRow(selectedRow.tableData.id);
        }}
        localization={LOCALIZATOR}
        options={{
          rowStyle: (rowData) => ({
            backgroundColor:
              selectedRow === rowData.tableData.id ? "#59A7F8" : "#EEE",
          }),
          exportButton: {
            csv: true,
            pdf: false,
          },
          exportAllData: true,
          search: false,
          pageSizeOptions: [0],
          actionsColumnIndex: 0,
          headerStyle: {
            zIndex: 1,
            fontFamily: "Flama-Regular",
          },
        }}
      />
    </div>
  );
};

export default TableCertificate;
