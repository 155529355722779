import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { LOCALIZATOR } from "../../../Constants/Table";
import tableIcons from "../IconsTable";
import Cookies from "universal-cookie";

const TableAccountSummary = ({ columns, data, setDataService }) => {
  const cookies = new Cookies();
  const [itemSelect, setItemSelect] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    const society = cookies.get("society");
    if (itemSelect) {
      const dataDetallePA = {
        Sociedad: society,
        Documento: itemSelect.DOC_NO,
        Perido: itemSelect.GJAHR,
      };
      setDataService(dataDetallePA);
    }
  }, [itemSelect]);

  return (
    <div>
      <MaterialTable
        columns={columns}
        title=""
        data={data}
        icons={tableIcons}
        onRowClick={(evt, selectedRow) => {
          setItemSelect(selectedRow);
          setSelectedRow(selectedRow.tableData.id);
        }}
        localization={LOCALIZATOR}
        options={{
          rowStyle: (rowData) => ({
            backgroundColor:
              selectedRow === rowData.tableData.id ? "#59A7F8" : "#EEE",
          }),
          exportButton: {
            csv: true,
            pdf: false,
          },
          exportAllData: true,
          search: false,
          pageSizeOptions: [0],
          actionsColumnIndex: -1,
          headerStyle: {
            zIndex: 1,
            fontFamily: "Flama-Regular",
          },
        }}
      />
    </div>
  );
};

export default TableAccountSummary;
