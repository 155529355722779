import React, { useState, useEffect } from "react";

import { RingLoader } from "react-spinners";

import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import { IMAGE } from "../../Constants/Image";
import * as ProviderService from "../../services/ProviderService";
import axios from "axios";
import swal from "sweetalert";

function ChangeSection({ setLoginSection, setChangeSection }) {
  const cancelTokenSource = axios.CancelToken.source();
  const [email, setEmail] = useState("");
  const [emailAlert, setEmailAlert] = useState("");
  const [confirmAlert, setConfirmAlert] = useState("");
  const [confirm, setConfirm] = useState("");
  const [newPasswordAlert, setNewPasswordAlert] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const [showLoader, setShowLoader] = useState(false);

  const handlerClick = async (e) => {
    /**
     * React - Preventing Form Submission
     * In javascript you can prevent that by using an event handler and
     * calling e.preventDefault() on button click, or form submit. e is
     * the event object passed into the event handler.
     */
    e.preventDefault();
    if (validationsNew()) {
      setShowLoader(true);
      let dataService = {
        Usuario: email,
        Clave: newPassword,
        NuevaClave: confirm,
      };
      ProviderService.cambioClave(dataService)
        .then((response) => {
          console.log(response);
          if (response.data.name === "Error") {
            swal({
              title: "Error",
              text: response.data.message,
              icon: "error",
            });
            setShowLoader(false);
          } else {
            setShowLoader(false);
            let data = response.data["n0:PortalProveedoresDataResponse"];
            let consulta = data["cambioClave"];
            if (consulta) {
              if (consulta["E_MENS"] === "Contraseña actualizada") {
                swal({
                  title: "Cambio de Clave",
                  text: consulta["E_MENS"],
                  icon: "success",
                });
                setChangeSection(false);
                setLoginSection(true);
              } else {
                swal({
                  title: "¡Alerta!",
                  text: consulta["E_MENS"],
                  icon: "warning",
                });
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
          swal({
            title: "Error",
            text: error,
            icon: "error",
          });
          setShowLoader(false);
        });
    }
  };

  useEffect(() => {
    if (email !== "") {
      validationEmail();
    }
  }, [email]);

  useEffect(() => {
    if (confirm !== "") {
      validationConfirm();
    }
  }, [confirm]);

  useEffect(() => {
    if (newPassword !== "") {
      validationNewPassword();
    }
  }, [newPassword]);
  /**
   * Email Validations
   */
  const validationEmail = () => {
    if (!email) {
      setEmailAlert("¡Debes escribir un usuario para iniciar sesión!");
      return;
    } else {
      setEmailAlert("");
      return;
    }
  };

  /**
   * Global Validations
   */
  const validationsNew = () => {
    if (!email && !newPassword && !confirm) {
      setEmailAlert("¡Debes escribir tu usuario!");
      setNewPasswordAlert("¡Debes escribir la contraseña actual!");
      setConfirmAlert("¡Debes ingresar la nueva la contraseña!");
      return false;
    } else if (!email) {
      setEmailAlert("¡Debes escribir tu usuario!");
      return false;
    } else if (!newPassword) {
      setNewPasswordAlert("¡Debes escribir la contraseña actual!");
      return false;
    } else if (!confirm) {
      setConfirmAlert("¡Debes ingresar la nueva la contraseña!");
      return false;
    } else {
      return true;
    }
  };

  /**
   * Password Validations
   */
  const validationNewPassword = () => {
    if (!newPassword) {
      setNewPasswordAlert("¡Debes registrar la contraseña actual!");
      return;
    } else if (newPassword.length < 6) {
      setNewPasswordAlert("¡Debe tener minimo 6 carácteres!");
      return;
    } else {
      setNewPasswordAlert("");
      return;
    }
  };

  // Confirm Password Validations
  const validationConfirm = () => {
    //Validation password
    validationNewPassword();
    if (!confirm) {
      setConfirmAlert("¡Debes ingresar la nueva la contraseña!");
      return;
    } else {
      setConfirmAlert("");
      return;
    }
  };

  return (
    <div>
      <div className="flex flex-col justify-center md:justify-start my-auto pt-8 md:pt-0 px-8 md:px-24 lg:px-32">
        {cancelTokenSource.cancel()}
        <img
          src={IMAGE.COLOMBINA_IMAGE}
          alt="imagen producto"
          className="w-1/3"
        />
        <p className="text-center text-3xl font-Flama-Regular font-bold my-2">
          Cambiar Clave
        </p>
        <form className="flex flex-col p-2" onSubmit={handlerClick}>
          <div className="flex flex-col pt-4">
            <label htmlFor="email" className="text-lg font-Flama-Regular">
              Usuario
            </label>
            <input
              placeholder="Usuario"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline"
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              value={email}
            />
            {emailAlert !== "" && (
              <div
                className="bg-red-100 border-l-4 border-red-500 text-red-700 p-2 mt-2"
                role="alert"
              >
                <p className="font-bold">Error:</p>
                <p>{emailAlert}</p>
              </div>
            )}
          </div>
          <div className="flex flex-col pt-2">
            <label htmlFor="password" className="text-lg font-Flama-Regular">
              Contraseña Actual
            </label>
            <div className="flex flex-row">
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline"
                type={showNewPassword ? "text" : "password"}
                placeholder="Contraseña actual"
                onChange={(event) => {
                  setNewPassword(event.target.value);
                }}
                value={newPassword}
              />
              <div
                className="rounded-full h-10 w-10 flex items-center justify-center"
                type="button"
                onClick={() => setShowNewPassword(!showNewPassword)}
              >
                {showNewPassword ? (
                  <VisibilityIcon></VisibilityIcon>
                ) : (
                  <VisibilityOffIcon></VisibilityOffIcon>
                )}
              </div>
            </div>
            {newPasswordAlert !== "" && (
              <div
                className="bg-red-100 border-l-4 border-red-500 text-red-700 p-2 mt-2"
                role="alert"
              >
                <p className="font-bold">Error:</p>
                <p>{newPasswordAlert}</p>
              </div>
            )}
          </div>
          <div className="flex flex-col pt-4">
            <label htmlFor="password" className="text-lg font-Flama-Regular">
              Nueva Contraseña
            </label>
            <div className="flex flex-row">
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline"
                type={showPasswordConfirm ? "text" : "password"}
                placeholder="Nueva contraseña"
                onChange={(event) => {
                  setConfirm(event.target.value);
                }}
                value={confirm}
              />
              <div
                className="rounded-full h-10 w-10 flex items-center justify-center"
                type="button"
                onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
              >
                {showPasswordConfirm ? (
                  <VisibilityIcon></VisibilityIcon>
                ) : (
                  <VisibilityOffIcon></VisibilityOffIcon>
                )}
              </div>
            </div>
            {confirmAlert !== "" && (
              <div
                className="bg-red-100 border-l-4 border-red-500 text-red-700 p-2 mt-2"
                role="alert"
              >
                <p className="font-bold">Error:</p>
                <p>{confirmAlert}</p>
              </div>
            )}
          </div>
          <button
            className="bg-black text-white font-bold text-lg hover:bg-gray-700 p-2 mt-8 font-Flama-Regular"
            type="submit"
          >
            Cambiar Clave
          </button>
        </form>
        {showLoader ? (
          <div className="flex items-center justify-center flex-col mt-6">
            <RingLoader color="blue" size={50} />
          </div>
        ) : null}
        <div className="text-center mt-10 mb-20">
          <label
            className="underline font-semibold  cursor-pointer"
            onClick={() => {
              setChangeSection(false);
              setLoginSection(true);
            }}
          >
            Regresar
          </label>
        </div>
      </div>
    </div>
  );
}

export default ChangeSection;
