import React, { useState } from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: theme.typography.pxToRem(12),
    fontFamily: "Flama-Regular",
    color: "black",
  },
  label: {
    fontSize: theme.typography.pxToRem(12),
    fontFamily: "Flama-Regular",
    color: "black",
  },
}));

const DataPicker = ({ handlerDate, type, enable }) => {
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = useState(null);
  const handleDateChange = (date) => {
    setSelectedDate(date);
    handlerDate(date, type);
  };

  return (
    <div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          className={classes.label}
          margin="normal"
          label={enable ? "Fecha " + type : ""}
          format="yyyy/MM/dd"
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default DataPicker;
